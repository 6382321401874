import request from '@/utils/request'

export function userPaymentConversionRateList (params) {
  return request({
    url: 'admin/statistics/user-payment-conversion-rate',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function activeUserPaymentConversionRateList (params) {
  return request({
    url: 'admin/statistics/active-payment-conversion-rate',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function gameAttractsMoneyAbility (data) {
  return request({
    url: '/analysis/gameAttractsMoneyAbility',
    data
  })
}
export function querySpeedMeasurementDelay (data) {
  return request({
    url: '/analysis/querySpeedMeasurementDelay',
    data
  })
}
export function paymentMethodConversionRate (params) {
  return request({
    url: 'admin/statistics/payment-method-conversion-rate',
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
export function paymentMethodConversionRateDetailByPayTypeAndDate (data) {
  return request({
    url: '/analysis/paymentMethodConversionRateDetailByPayTypeAndDate',
    data
  })
}

<template>
  <Layout class="MachineLogWrapper">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
        class="log-form"
        size="mini"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="appId">
          <!-- <el-select
            v-model="formData.appId"
            placeholder="应用"
            clearable
          >
            <el-option
              v-for="(item, index) in appIdList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select> -->
          <el-select
            v-model="formData.app_key"
            placeholder="选择APP"
            collapse-tags
            clearable
          >
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.app_key"
            />
            <el-option
              label="其他"
              value="other"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="version">
          <el-input
            v-model="formData.version"
            placeholder="版本号"
            clearable
          />
        </el-form-item>
        <el-form-item prop="countryCodeList">
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <div style="width: 180px">
          <el-row>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="handleSearch"
              >
                搜索
              </el-button>
            </el-col>
            <el-col :span="12">
              <download-excel
                :data="exportParam.data"
                :fields="exportParam.fields"
                :type="exportParam.type"
                :worksheet="exportParam.worksheet"
                :name="exportParam.filename"
              >
                <el-button type="primary">
                  导出
                </el-button>
              </download-excel>
            </el-col>
          </el-row>

          <!-- <el-button
            type="primary"
            @click="handleExport"
          >
            导出
          </el-button> -->
        </div>
      </template>
    </BHeader>
    <div
      v-loading="tableData.loading"
      class="overview"
    >
      <div class="items">
        <div class="num">
          {{ paidAmountTotal.toFixed(2) || '-' }}
        </div>
        <div class="name">
          付费金额
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ newTotal || '0' }}
        </div>
        <div class="name">
          新增用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ paidTotal || '0' }}
        </div>
        <div class="name">
          新增付费用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ (paidTotal / newTotal *100).toFixed(2) + "%" || '-' }}
        </div>
        <div class="name">
          付费转化率
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ ( paidAmountTotal / newTotal ).toFixed(4) || "-" }}
        </div>
        <div class="name">
          arpu
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ isNaN( paidAmountTotal / paidTotal )?"0.0000":(paidAmountTotal / paidTotal).toFixed(4) || '-' }}
        </div>
        <div class="name">
          arppu
        </div>
      </div>
    </div>
    <el-table
      v-loading="tableData.loading"
      :data="tableData.list"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        type="expand"
      >
        <template slot-scope="scope">
          <el-table
            :data="scope.row.by_country"
            :row-class-name="tableRowClassName"
            class="small-table"
            :header-cell-style="{
              background:'#e6e6e6'}"
          >
            {{ scope.row }}
            <el-table-column
              prop="nation_code"
              label="国家"
            >
              <template slot-scope="childrenRow">
                {{ countryCodeMap[childrenRow.row.nation_code] == undefined?childrenRow.row.nation_code: countryCodeMap[childrenRow.row.nation_code].value }}
              </template>
            </el-table-column>
            <el-table-column
              prop="user_registered"
              label="新增"
            />
            <el-table-column
              prop="user_paid"
              label="新增付费用户"
            />
            <el-table-column
              prop="order_created"
              label="新增订单"
            />
            <el-table-column
              prop="order_paid"
              label="成功付费订单数"
            />
            <el-table-column
              label="付费转化率"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.user_paid / childrenScope.row.user_registered *100).toFixed(2) }} %
              </template>
            </el-table-column>
            <el-table-column
              prop="paid_amount"
              label="新增付费金额"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.paid_amount * 1).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="subscription_amount"
              label="新增订阅金额"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.subscription_amount * 1).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="user_subscription"
              label="订阅人数"
            />
            <el-table-column
              prop="user_buy_gold"
              label="购买金币人数"
            />
            <el-table-column
              prop="gold_amount"
              label="新增金币金额"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.gold_amount * 1).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="topup_card_amount"
              label="新增时长卡金额"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.topup_card_amount * 1).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="arpu_amount"
              label="新增arpu"
            >
              <template slot-scope="childrenScope">
                {{ (childrenScope.row.paid_amount* 1 / childrenScope.row.user_registered ).toFixed(4) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="arppu_amount"
              label="新增arppu"
            >
              <template slot-scope="childrenScope">
                {{ isNaN(childrenScope.row.paid_amount* 1 / childrenScope.row.user_paid )?"0.0000":(childrenScope.row.paid_amount* 1 / childrenScope.row.user_paid ).toFixed(4) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        label="日期"
      />
      <el-table-column
        prop="user_registered"
        label="新增"
      />
      <el-table-column
        prop="user_paid"
        label="新增付费用户"
      />
      <el-table-column
        prop="order_created"
        label="新增订单"
      />
      <el-table-column
        prop="order_paid"
        label="成功付费订单数"
      />
      <el-table-column
        label="付费转化率"
      >
        <template slot-scope="scope">
          {{ (scope.row.user_paid / scope.row.user_registered *100).toFixed(2) }} %
        </template>
      </el-table-column>
      <el-table-column
        label="新增付费金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.paid_amount * 1).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="subscription_amount"
        label="新增订阅金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.subscription_amount* 1).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user_subscription"
        label="订阅人数"
      />
      <el-table-column
        prop="gold_amount"
        label="新增金币金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.gold_amount * 1).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="user_buy_gold"
        label="购买金币人数"
      />
      <el-table-column
        prop="topup_card_amount"
        label="新增时长卡金额"
      >
        <template slot-scope="scope">
          {{ (scope.row.topup_card_amount* 1).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="arpu_amount"
        label="新增arpu"
      >
        <template slot-scope="scope">
          {{ (scope.row.paid_amount * 1 / scope.row.user_registered ).toFixed(4) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="arppu_amount"
        label="新增arppu"
      >
        <template slot-scope="scope">
          {{ isNaN(scope.row.paid_amount* 1 / scope.row.user_paid )?"0.0000":(scope.row.paid_amount* 1 / scope.row.user_paid ).toFixed(4) }}
        </template>
      </el-table-column>
    </el-table>
  </Layout>
</template>

<script>
import {
  countryCodeList
} from '@/utils/selectOptions'
import { userPaymentConversionRateList } from '@/api/product-operation.js'
import { cloneDeep } from 'lodash'
// import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
// import { getMachineRecord } from '@/api/log.js'
// import { batchQueryParamsForExport } from '@/utils'
import { getUserNationStatistics } from '@/api/electronicEntity.js'
import { format } from 'fecha'
// import { getAppKeys } from '@/api/blacklist.js'
import { commonRequestGet } from '@/api/commonApi.js'

const getLastWeek = () => {
  const days = 7
  const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(new Date((Date.now())).getTime(), 'YYYY-MM-DD')]
}

const countryCodeMap = countryCodeList.reduce((map, item) => {
  map[item.name] = item
  return map
}, {})
const formKey = 'FORM'
export default {
  name: 'PaidConversionRate',
  data () {
    return {
      nationList: [],
      filterNationList: [],
      appKeysList: [],
      countryCodeList,
      countryCodeMap,
      tableData: {
        loading: false,
        list: []
      },

      formData: {
        dateRange: getLastWeek(),
        countryCodeList: [],
        pageNum: 1,
        pageSize: 20,
        version: ''
      },
      rules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          const dateTime = time.getTime()
          return dateTime > new Date((Date.now())).getTime()
        }
      },
      exportParam: {
        fields: {
          // 日期: 'date',
          // 新增: 'new_num',
          // 新增付费用户: 'paid_num',
          // 新增订单: 'order_num',
          // 成功付费订单数: 'order_paid',
          // 付费转化率: 'paid_conversion_rate',
          // 新增付费金额: 'paid_amount',
          // 新增订阅金额: 'subscription_amount',
          // 新增金币金额: 'gold_coin_amount',
          // 新增时长卡金额: 'long_card_amount',
          // 新增arpu: 'arpu',
          // 新增arppu: 'arppu'
          日期: 'date',
          新增: 'user_registered',
          新增付费用户: 'user_paid',
          新增订单: 'order_created',
          成功付费订单数: 'order_paid',
          付费转化率: 'paid_conversion_rate',
          新增付费金额: 'paid_amount',
          新增订阅金额: 'subscription_amount',
          订阅人数: 'user_subscription',
          购买金币人数: 'user_buy_gold',
          新增金币金额: 'gold_amount',
          新增时长卡金额: 'topup_card_amount',
          新增arpu: 'arpu',
          新增arppu: 'arppu'
        },
        data: [],
        filename: '新增付费转化率',
        worksheet: 'sheet1',
        type: 'xls'
      }

    }
  },
  computed: {
    'paidAmountTotal' (res) {
      var price = 0
      res.tableData.list.forEach(element => {
        price += element.paid_amount * 1
      })
      return price
    },
    'newTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.user_registered * 1
      })
      return num
    },
    'paidTotal' (res) {
      var num = 0
      res.tableData.list.forEach(element => {
        num += element.user_paid * 1
      })
      return num
    }
  },
  watch: {
    'tableData.list': {
      handler: function () {
        var data = []
        this.tableData.list.forEach(element => {
          element.paid_conversion_rate = (element.user_paid / element.user_registered * 100).toFixed(2) + '%'
          element.paid_amount = (element.paid_amount * 1).toFixed(2)
          element.subscription_amount = (element.subscription_amount * 1).toFixed(2)
          element.gold_amount = (element.gold_amount * 1).toFixed(2)
          element.topup_card_amount = (element.topup_card_amount * 1).toFixed(2)
          element.arpu = (element.paid_amount * 1 / element.user_registered).toFixed(4)
          element.arppu = isNaN(element.paid_amount * 1 / element.user_paid) ? '0.0000' : (element.paid_amount * 1 / element.user_paid).toFixed(4)
          data.push(element)
        })
        this.exportParam.data = data
      }
    }
  },
  mounted () {
    this.handleSearch()
    this.getAppKeys()
  },
  created () {
    this.queryNationList()
      .then(() => {
        const storageFormData = sessionStorage.getItem(formKey)
        if (storageFormData) {
          this.formData = JSON.parse(storageFormData)
        }
        this.queryDataList()
      })
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      const num = (row.user_paid / row.user_registered * 100).toFixed(2)
      if (num <= 0.5) {
        return 'red-row'
      } else if (num >= 1) {
        return 'green-row'
      }
      return ''
    },
    handleSearch () {
      this.tableData.loading = true
      this.queryDataList()
    },
    computedIp (connectInfo) {
      const info = JSON.parse(connectInfo || '{}')
      return ((info.data || {}).server_info || {}).innerip || '/'
    },
    computedTime (second) {
      const min = Math.floor(second % (60 * 60))
      const s = ('0' + second % 60).slice(-2)
      const m = ('0' + Math.floor(min / 60)).slice(-2)
      const H = Math.floor(second / (60 * 60))
      const h = H > 9 ? H : '0' + H
      return `${h}:${m}:${s}`
    },
    // 导出
    // queryLogListForExport () {
    //   return new Promise((resolve, reject) => {
    //     this.$refs.form.validate(valid => {
    //       if (valid) {
    //         this.logListInfo.loading = true
    //         const formData = cloneDeep(this.formData)
    //         /* eslint-disable camelcase */
    //         const [get_resource_start_time, get_resource_end_time] = formData.daterange || []
    //         let { use_time_s, use_time_e } = formData
    //         if (use_time_s) {
    //           use_time_s = use_time_s * 60 * 60
    //         }
    //         if (use_time_e) {
    //           use_time_e = use_time_e * 60 * 60
    //         }
    //         delete formData.daterange

    //         const paramsList = batchQueryParamsForExport({
    //           params: {
    //             ...formData,
    //             page_size: 100,
    //             use_time_s,
    //             use_time_e,
    //             get_resource_start_time,
    //             get_resource_end_time
    //           },
    //           total: this.logListInfo.total
    //         })

    //         Promise.all(paramsList.map(params => getMachineRecord(params)))
    //           .then(resultList => {
    //             const result = resultList.reduce((res, item) => {
    //               return res.concat(item.data.list)
    //             }, [])
    //             resolve(result)
    //           })
    //           .finally(() => {
    //             this.logListInfo.loading = false
    //           })
    //       } else {
    //         resolve(false)
    //       }
    //     })
    //   })
    // },
    // formatToDwonload (data) {
    //   this.batchUploadTemplate.list = data
    // },
    // downloadExcel () {
    //   const header = this.batchUploadTemplate.header
    //   const list = this.batchUploadTemplate.list
    //   const fields = this.batchUploadTemplate.fields
    //   downloadExcel(list, header, fields, '机器使用日志' + '_' + new Date().toLocaleDateString())
    // },
    handleExport () {
      // this.queryLogListForExport()
      //   .then(data => {
      //     if (data) {
      //       this.formatToDwonload(data)
      //       this.downloadExcel()
      //     }
      //   })
    },
    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        start_time: startTime,
        end_time: endTime,
        // app_key: form.appKey,
        // version: version,
        nation_code: form.countryCodeList.map(item => {
          return item.countryCode
        })
      }
    },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      // console.log(res, '1111111')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    },
    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nation.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const query = this.formatQuery(this.formData)
          delete query.countryCodeList
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          userPaymentConversionRateList(query)
            .then(res => {
              this.tableData.list = res || []
            })
            .finally(() => {
              this.tableData.loading = false
            })
        }
      })
    },
    expandClick () {
    }
  }
}
</script>

<style lang="less">
  .MachineLogWrapper{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
<style scoped lang="less">
 .el-table /deep/ .red-row {
    color: red;
  }

  .el-table /deep/ .green-row {
    color: green;
  }
  .small-table /deep/ .el-table__row{
    background-color: #e6e6e6;
  }
  .log-form{
    .el-input,
    .el-select{
      width: 150px;
    }
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
      width: 270px;
    }
  }
  .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
    .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }
    }
</style>
